/* eslint-disable no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const CityDisplayName = styled.div`
	&.city-display-name {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)};
	}
`;

export const CityCountryName = styled.span`
	&.city-country-name {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
	}
`;
export const StyledCityCardContainer = styled.div`
	cursor: pointer;

	&.city-card-wrapper {
		width: 11.25rem;
		min-height: 16.25rem;
		margin: 0.625rem 1.5rem 1.5rem 0;
	}
`;
