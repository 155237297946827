import React, {
	forwardRef,
	MouseEventHandler,
	useEffect,
	useState,
} from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classNames from 'classnames';

import CategorySubCategoryIcon from 'Components/common/categoriesAndSubCategories/categorySubCategoryIcon';
import { ICategoriesAndSubCategoriesProps } from 'Components/common/categoriesAndSubCategories/interface';
import {
	HorizontalTabSection,
	SubCategoriesContainer,
	SubCategory,
	Tab,
	TabContentContainer,
	TabHighlight,
} from 'Components/common/categoriesAndSubCategories/style';
import Conditional from 'Components/common/conditional';

import { trackEvent } from 'Utils/analytics';
import { getCategoryIconUrl } from 'Utils/imageUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { strings } from 'Constants/strings';

const Swiper = dynamic(
	() =>
		import(
			/* webpackChunkName: 'CustomSwiper' */ 'Components/common/customSwiper'
		),
);

const CategoriesAndSubCategories = forwardRef<
	HTMLDivElement,
	ICategoriesAndSubCategoriesProps
>(
	(
		{ categoriesAndSubcategories, className, city, isMobile, trackClick },
		ref,
	) => {
		const [activeCategory, setActiveCategory] = useState(0);

		useEffect(() => {
			setActiveCategory(Array.from(categoriesAndSubcategories.keys())[0]);
		}, [categoriesAndSubcategories]);

		const trackClickCategory = (name: string) => {
			trackEvent({
				eventName: ANALYTICS_EVENTS.BROWSE_BY_THEMES.THEME_TAB,
				[ANALYTICS_PROPERTIES.BROWSE_BY_THEMES.LABEL]: name,
			});
		};

		const changeActiveCategory = (id: number) => {
			setActiveCategory(id);
		};

		const getCategoryTabs = (): React.ReactNode[] => {
			const catAndSubcatArray = Array.from(
				categoriesAndSubcategories.values(),
			);
			const lastIndex = catAndSubcatArray.length - 1;
			return catAndSubcatArray.map(({ entityDetails }, index) => {
				const { id, actualName, heading, name } = entityDetails;
				const svgUrl = getCategoryIconUrl({
					entityId: id,
					isCategory: true,
				});
				const onClick: MouseEventHandler<HTMLLIElement> = () => {
					trackClickCategory(actualName as string);
					changeActiveCategory(+id);
				};
				const title = city ? heading : name;

				return (
					<Tab
						$isActive={activeCategory === id}
						onClick={onClick}
						role='tab'
						aria-selected={activeCategory === id ? 'true' : 'false'}
						key={id}
						$isLast={index === lastIndex}
						$isFirst={index === 0}
						className={classNames({
							'first-cat-tab': index === 0,
						})}
					>
						<TabContentContainer>
							<CategorySubCategoryIcon
								isActive={activeCategory === id}
								svgUrl={svgUrl}
								isCategory
								name={title!}
							/>
							<span className='categoryText'>{title}</span>
							<Conditional if={activeCategory === id}>
								<TabHighlight />
							</Conditional>
						</TabContentContainer>
					</Tab>
				);
			});
		};

		if (!categoriesAndSubcategories.size || !activeCategory) return null;

		return (
			<HorizontalTabSection className={className} ref={ref}>
				<Swiper
					slideWidth='variable'
					showBlurNearEdges
					nextPrevControls={isMobile ? 'hide' : 'show'}
					nextPrevControlPosX='-12px'
					nextPrevControlPosY='12px'
					nextPrevControlSize='small'
				>
					{getCategoryTabs()}
				</Swiper>
				{categoriesAndSubcategories.size &&
					Array.from(categoriesAndSubcategories.values()).map(
						({ subCategories, entityDetails }) => {
							const {
								id: categoryId,
								name,
								as,
								href,
								actualName,
								heading,
							} = entityDetails;

							const viewAllText = city
								? strings.formatString(
										strings.BROWSE_BY_THEMES
											.VIEW_ALL_CATEGORY,
										heading,
								  )
								: strings.formatString(
										strings.BROWSE_BY_THEMES
											.VIEW_ALL_CATEGORY,
										name,
								  );

							return (
								<SubCategoriesContainer
									$isActive={activeCategory === categoryId}
									key={`sub_cat_${categoryId}`}
								>
									{subCategories &&
										subCategories.map(
											({
												name,
												id,
												as,
												href,
												actualName,
												heading,
											}) => {
												const svgUrl =
													getCategoryIconUrl({
														entityId: id,
														isCategory: false,
													});

												const subCategoryText = city
													? heading
													: name;

												return (
													<Link
														// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
														href={href}
														as={as}
														passHref
														key={href}
														legacyBehavior
													>
														<SubCategory
															onClick={() => {
																if (trackClick)
																	trackClick({
																		name: actualName as string,
																	});
															}}
														>
															<CategorySubCategoryIcon
																isActive={false}
																isCategory={
																	false
																}
																name={
																	subCategoryText!
																}
																svgUrl={svgUrl}
																className='subCategoryIcon'
															/>
															<span className='subCategoryText'>
																{
																	subCategoryText
																}
															</span>
														</SubCategory>
													</Link>
												);
											},
										)}
									<Link
										/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */
										href={href}
										as={as}
										passHref
										legacyBehavior
									>
										<SubCategory
											onClick={() =>
												trackClick?.({
													name: actualName,
												})
											}
										>
											<span className='subCategoryText'>
												{viewAllText}
											</span>
										</SubCategory>
									</Link>
								</SubCategoriesContainer>
							);
						},
					)}
			</HorizontalTabSection>
		);
	},
);

CategoriesAndSubCategories.displayName = 'CategoriesAndSubCategories';

export default CategoriesAndSubCategories;
