import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import LateralCarousel, {
	LateralCarouselOnSlideChanged,
	LateralCarouselRef,
} from 'Components/common/lateralCarousel';
import RenderOneOf from 'Components/common/renderOneOf';

import { getCityUrl } from 'Utils/cityUtils';
import { sortObjectAlphabetically } from 'Utils/gen';
import {
	getCitiesMap,
	getCurrentCityCode,
	getDiscoverableCityCodes,
} from 'Utils/stateUtils';

import { fetchCities } from 'Thunks/city';

import {
	NEAR_BY_CITIES_COUNT,
	TOP_DESTINATIONS_COUNT,
} from 'Constants/constants';

import CityCard from './cityCardV2';

const CitiesCardListWrapper = styled.div`
	.arrow-button-wrapper {
		top: 6.75rem;
	}
`;

const AllCardsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 78.125rem;
`;

type CityCardListElementsProps = {
	paramLang?: string;
	currentCityCode?: string;
	requestCities?: (...args: any[]) => any;
	citiesCarouselRef?: LateralCarouselRef;
	onSlideChanged?: LateralCarouselOnSlideChanged;
};

class CityCardListElements extends Component<CityCardListElementsProps> {
	componentDidMount() {
		// @ts-expect-error TS(2339): Property 'cityCodes' does not exist on type 'Reado... Remove this comment to see the full error message
		const { cityCodes, requestCities } = this.props;
		// @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
		if (!cityCodes || cityCodes.length === 0) requestCities();
	}

	getCityCardList() {
		const {
			paramLang,
			// @ts-expect-error TS(2339): Property 'cityCodes' does not exist on type 'Reado... Remove this comment to see the full error message
			cityCodes,
			// @ts-expect-error TS(2339): Property 'citiesMap' does not exist on type 'Reado... Remove this comment to see the full error message
			citiesMap,
			// @ts-expect-error TS(2339): Property 'preCalculatedCityCodes' does not exist o... Remove this comment to see the full error message
			preCalculatedCityCodes,
			// @ts-expect-error TS(2339): Property 'showAllCards' does not exist on type 'Re... Remove this comment to see the full error message
			showAllCards,
			// @ts-expect-error TS(2339): Property 'showAlphabetically' does not exist on ty... Remove this comment to see the full error message
			showAlphabetically,
			// @ts-expect-error TS(2339): Property 'isHomePage' does not exist on type 'Read... Remove this comment to see the full error message
			isHomePage,
			// @ts-expect-error TS(2339): Property 'isNearByCitiesSection' does not exist on... Remove this comment to see the full error message
			isNearByCitiesSection,
			citiesCarouselRef,
			onSlideChanged,
		} = this.props;
		const cityCodesToShow = preCalculatedCityCodes
			? preCalculatedCityCodes
			: showAlphabetically
			? sortObjectAlphabetically(cityCodes)
			: cityCodes;
		const isCardsArrowsRequired = cityCodesToShow.length > 6;
		const filteredCityCodes = isHomePage
			? cityCodesToShow.slice(0, TOP_DESTINATIONS_COUNT)
			: isNearByCitiesSection
			? cityCodesToShow.slice(0, NEAR_BY_CITIES_COUNT)
			: cityCodesToShow;
		const cityCards = filteredCityCodes
			.map((cityCode: any) => citiesMap?.[cityCode])
			.map((city: any, index: any) => (
				<CityCard
					city={city}
					key={index}
					pageHref={getCityUrl({
						city,
						paramLang,
					})}
					// @ts-expect-error TS(2769): No overload matches this call.
					renderImageInServer={true}
					className={showAllCards ? 'margin-to-right' : ''}
				/>
			));
		const settings = {
			className: 'cities-card-list',
			elementsToSlide: 5,
			elementsToShow: 6,
			hidePrevNextArrows: !!citiesCarouselRef && !!onSlideChanged,
		};

		return (
			<CitiesCardListWrapper
				className={`horizontal-scroll-wrapper cities-list-v2-scroll-wrapper ${
					isCardsArrowsRequired ? '' : 'no-arrow-button-city-search'
				}`}
			>
				<RenderOneOf
					positionalConditions={[!showAllCards, showAllCards]}
				>
					<LateralCarousel
						carouselRef={citiesCarouselRef}
						onSlideChanged={onSlideChanged}
						buttonsFromTop={'11.2rem'}
						{...settings}
					>
						{cityCards}
					</LateralCarousel>
					<AllCardsContainer>{cityCards}</AllCardsContainer>
				</RenderOneOf>
			</CitiesCardListWrapper>
		);
	}

	registerNext = (func: any) => {
		(this as any).nextCallback = func;
	};

	registerPrev = (func: any) => {
		(this as any).prevCallback = func;
	};

	render() {
		return this.getCityCardList();
	}
}

const mapStateToProps = (state: any, ownProps: any) => ({
	paramLang: ownProps.paramLang,
	cityCodes: getDiscoverableCityCodes(state),
	citiesMap: getCitiesMap(state),
	currentCityCode: getCurrentCityCode(state),
});

const mapDispatchToProps = (dispatch: any) => ({
	requestCities() {
		dispatch(fetchCities());
	},
});

const CityCardList = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CityCardListElements);

(CityCardList as any).propTypes = {
	showAllCards: PropTypes.bool,
	preCalculatedCityCodes: PropTypes.array,
	noOfCards: PropTypes.number,
	showAlphabetically: PropTypes.bool,
};

(CityCardList as any).defaultProps = {
	showAllCards: false,
};

export default CityCardList;
