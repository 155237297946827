import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import ArrowButtonList from 'Components/common/arrowButtonList';
import type { SwiperSlideChangedCbProps } from 'Components/common/customSwiper/interface';

const CarouselWrapper = styled.div`
	position: relative;

	.product-card-wrap {
		margin: 0.75rem;
	}
`;

const InnerCarouselWrapper = styled.div<{
	showHoverAnimation?: boolean;
	leftPosition?: string;
}>`
	display: inline-flex !important;
	position: relative;
	transition: ${props =>
		(props as any).isSubCategoryCarousel
			? 'left 280ms ease-in-out'
			: 'left 500ms ease-in-out'};

	&:hover {
		${({ showHoverAnimation, leftPosition }) =>
			showHoverAnimation &&
			`left: ${leftPosition} !important;
		transition: left 300ms ease-out;`}
	}
`;

export type CarouselRefActions = {
	totalElements: number;
	prevSlide: () => void;
	nextSlide: () => void;
};

export type LateralCarouselRef = MutableRefObject<CarouselRefActions | null>;
export type LateralCarouselOnSlideChanged = (
	props: Omit<SwiperSlideChangedCbProps, 'index'>,
) => void;

type OwnProps = {
	className?: string;
	innerWrapperClassName?: string;
	elementsToShow: number;
	elementsToSlide: number;
	isSubCategoryCarousel?: boolean;
	flatArrows?: boolean;
	hasEffectOnHover?: boolean;
	buttonsFromTop?: string;
	children: any;
	isCollectionCarousel?: boolean;
	isCardsArrowsRequired?: boolean;
	carouselRef?: LateralCarouselRef;
	onSlideChanged?: LateralCarouselOnSlideChanged;
	hidePrevNextArrows?: boolean;
	customChildrenLength?: number;
};

const LateralCarousel = ({
	className = '',
	children,
	innerWrapperClassName = '',
	isSubCategoryCarousel = true,
	isCollectionCarousel = false,
	elementsToSlide,
	elementsToShow,
	flatArrows = false,
	isCardsArrowsRequired = true,
	hasEffectOnHover = false,
	buttonsFromTop,
	carouselRef,
	onSlideChanged,
	hidePrevNextArrows = false,
	customChildrenLength,
}: OwnProps) => {
	const target = useRef<HTMLDivElement>(null);

	const [hidePrev, setHidePrev] = React.useState(true);
	const [hideNext, setHideNext] = React.useState(false);
	const [animateOnHover, setAnimateOnHover] = React.useState(true);

	const getChildrenAsArray = useCallback(() => {
		if (Array.isArray(children)) return children;
		else if (children) return [children];
		return [];
	}, [children]);

	const getChildWidth = useCallback(() => {
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		return target.current.offsetWidth / getChildLength();
	}, [getChildrenAsArray]);

	const getChildLength = useCallback(() => {
		return customChildrenLength || getChildrenAsArray().length;
	}, [customChildrenLength, getChildrenAsArray]);

	const prev = () => {
		const element = target.current,
			childWidth = getChildWidth(),
			slideWidth = childWidth * elementsToSlide,
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			currentLeft = parseFloat(element.style.left);

		// @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		if (parseFloat(currentLeft) + slideWidth > 0)
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			element.style.left = `0px`;
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		else element.style.left = `${parseFloat(currentLeft) + slideWidth}px`;

		scrollListener();
	};

	const next = () => {
		const element = target.current,
			childWidth = getChildWidth(),
			slideWidth = childWidth * elementsToSlide,
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			currentLeft = parseFloat(element.style.left),
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			elWidth = element.clientWidth;

		if (
			elWidth - Math.abs(currentLeft) - slideWidth <
			elementsToShow * childWidth
		)
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			element.style.left = `-${elWidth - childWidth * elementsToShow}px`;
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		else element.style.left = `${currentLeft - slideWidth}px`;

		scrollListener();
	};

	// update the hidePrev and hideNext state after every transition
	const scrollListener = () => {
		const element = target.current,
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			currentLeft = parseFloat(element.style.left),
			childWidth = getChildWidth(),
			// @ts-expect-error TS(2531): Object is possibly 'null'.
			elWidth = element.clientWidth;

		// Disable the hover animation until the user leaves the carousel
		setAnimateOnHover(false);

		// @ts-expect-error TS(2531): Object is possibly 'null'.
		if (element.style.left === '0px') setHidePrev(true);
		else setHidePrev(false);

		if (
			Math.round(Math.abs(currentLeft) + elementsToShow * childWidth) ===
			elWidth
		)
			setHideNext(true);
		else setHideNext(false);
	};

	useEffect(() => {
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		target.current.style.left = '0px';
	}, []);

	useEffect(() => {
		const childWidth = getChildWidth();
		const childrenLength = getChildLength();

		if (childWidth * elementsToShow >= childWidth * childrenLength)
			setHideNext(true);
	}, [elementsToShow, getChildWidth, getChildrenAsArray]);

	useEffect(() => {
		if (carouselRef) {
			carouselRef.current = {
				totalElements: children.length,
				prevSlide: prev,
				nextSlide: next,
			};
		}
	}, [carouselRef]);

	useEffect(() => {
		onSlideChanged?.({
			isLeftArrowEnabled: !hidePrev,
			isRightArrowEnabled: !hideNext,
		});
	}, [hideNext, hidePrev, onSlideChanged]);

	return (
		<CarouselWrapper className={className}>
			<ArrowButtonList
				onPrev={prev}
				onNext={next}
				hidePrev={hidePrev || hidePrevNextArrows}
				hideNext={hideNext || hidePrevNextArrows}
				isSubCategoryCarousel={isSubCategoryCarousel}
				isCollectionCarousel={isCollectionCarousel}
				flatArrows={flatArrows}
				isCardsArrowsRequired={isCardsArrowsRequired}
				top={buttonsFromTop}
			>
				<InnerCarouselWrapper
					className={innerWrapperClassName}
					ref={target}
					showHoverAnimation={
						hasEffectOnHover &&
						(hidePrev || hideNext) &&
						animateOnHover
					}
					onMouseLeave={() => setAnimateOnHover(true)}
					leftPosition={
						hidePrev
							? '-80px'
							: // @ts-expect-error TS(2531): Object is possibly 'null'.
							  `${parseFloat(target.current.style.left) + 80}px`
					}
				>
					{children}
				</InnerCarouselWrapper>
			</ArrowButtonList>
		</CarouselWrapper>
	);
};

export default LateralCarousel;
